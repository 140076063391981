import { Tag } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export default function BigBang({ text, onSelectionChange }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const splitted = text.split(' ').map((t) => ({ text: t, selected: false }));
    setItems(splitted);
    if (onSelectionChange) {
      onSelectionChange(splitted);
    }
  }, [text]);

  return (
    <div>
      {items.map((s, i) => (
        <Tag
          m={1}
          size="lg"
          backgroundColor={s.selected ? '#f8cd46' : '#f2f2f7'}
          key={i}
          variant={s.selected ? 'solid' : 'subtle'}
          colorScheme={s.selected ? 'teal' : undefined}
          onClick={() => {
            items[i].selected = !items[i].selected;
            const newItems = [...items];
            setItems(newItems);
            if (onSelectionChange) {
              onSelectionChange(newItems);
            }
          }}
        >
          {s.text}
        </Tag>
      ))}
    </div>
  );
}
