// ouput type
// {
//     type: 'lark',
//     display_name: '飞书表格',
// }

import { useEffect, useImperativeHandle, useState } from 'react';
import { ajax } from './ajax';
import {
  Box,
  Card,
  CardBody,
  Link,
  Select,
  Text,
  forwardRef,
} from '@chakra-ui/react';

export const OutputTargetSelector = forwardRef((props, ref) => {
  const [outputTypeOptions, setOutputTypeOptions] = useState([]);
  const [outputType, setOutputType] = useState('');

  const [secondaryOutputOptions, setSecondaryOuputOptions] = useState([]);
  const [secondaryOutput, setSecondaryOutput] = useState('');

  useImperativeHandle(ref, () => ({
    generateOuputFuntion,
  }));

  useEffect(() => {
    ajax({ url: '/user/info', method: 'GET' }).then((info) => {
      // we need to set more options, right now we just use lark
      if (info?.data?.user?.bitables?.[0]) {
        const larkOption = {
          value: 'lark',
          label: '飞书表格',
          data: info?.data?.user?.bitables?.[0],
        };
        setOutputTypeOptions([larkOption]);
        setOutputType(larkOption.value);
        initSecondaryType(larkOption);
      }
    });
  }, []);

  function outputTypeChange(event) {
    console.log('ouptuttype change: ', event.target.value);
    setOutputType(event.target.value);
  }

  function secondaryOutputChange(event) {
    console.log('secondary output change: ', event.target.value);
    setSecondaryOutput(event.target.value);
  }

  // 根据当前的选择，生成用于导出的函数 f, 业务方直接调用 f 即可导出
  function generateOuputFuntion() {
    if (!outputType) {
      return null;
    }
    // 如果是飞书，那么必然有第二个选项
    if (outputType === 'lark' && !secondaryOutput) {
      return null;
    } else {
      // 飞书
      return async ({
        type, // 'vocabulary' | 'note'
        sentence, // 句子
        word, // 单词
        reading, // 发音
        definition, // 释义
        translation, // 翻译
        extra, // 备注
        toast, // 用来显示结果用的
      }) => {
        try {
          const table_id = secondaryOutput;
          await ajax({
            url: '/bitable/add-record',
            method: 'POST',
            body: {
              table_id,
              fields: {
                单词: word,
                音标: reading,
                释义: definition,
                句子: sentence,
                翻译: translation,
                备注: extra,
              },
            },
          });
          toast({
            position: 'bottom',
            render: () => {
              // 拿一下多维表格的url
              const selectedOption = outputTypeOptions.find(
                (o) => o.value === outputType
              );
              const url = selectedOption?.data?.app?.url + '?table=' + table_id;

              return (
                <Box p={4}>
                  <Card>
                    <CardBody>
                      <Text>保存成功! </Text>
                      <Link href={url} color="teal">
                        前往飞书查看
                      </Link>
                    </CardBody>
                  </Card>
                </Box>
              );
            },
          });
          return null;
        } catch (e) {
          toast({
            title: e.msg || '导出失败',
            status: 'error',
          });
          return null;
        }
      };
    }
  }

  // 按照已经选择的类别1，初始化类别2选择
  async function initSecondaryType(option) {
    if (option.value === 'lark') {
      // 如果是飞书，则需要初始化数据表列表
      const tables = await ajax({
        url: '/bitable/list',
        method: 'GET',
      });
      if (tables.data.items) {
        const options = tables.data.items.map((item) => ({
          value: item.table_id,
          label: item.name,
        }));
        setSecondaryOuputOptions(options);

        if (options.length > 0) {
          setSecondaryOutput(options[0].value);
        }
      }
    }
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" p={3}>
      <Text w="fit-content" flexShrink={0}>
        保存选项：
      </Text>
      <Select variant='flushed' value={outputType} onChange={outputTypeChange}>
        {outputTypeOptions.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>

      {secondaryOutputOptions?.length > 0 ? (
        <Select ml={2} value={secondaryOutput} onChange={secondaryOutputChange}>
          {secondaryOutputOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      ) : null}
    </Box>
  );
});
