function bingUrl(word) {
  return '/dict/bing?query=' + word;
}

export function searchBing(word) {
  return fetch(bingUrl(word))
    .then((r) => r.json())
    .then((data) => {
      const html = data.data;
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(html, 'text/html');
      const headWord = doc.querySelector('#headword')?.textContent;
      const result = [];
      for (let li_pos of doc.querySelectorAll('.qdef ul li')) {
        const pos = li_pos.querySelector('.pos');
        const pos_str = pos?.textContent;
        for (let de_co of li_pos.querySelectorAll('.def')) {
          const def_str = de_co?.textContent;
          result.push({
            headWord,
            pos: pos_str,
            def: def_str,
          });
        }
      }
      return result;
    });
}
