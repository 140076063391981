import {
  Alert,
  AlertIcon,
  VStack,
  Divider,
  Input,
  FormLabel,
  Link,
  FormControl,
  Box,
  Button,
  useToast,
  Spinner,
  Text,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ajax } from './ajax';
import { Fragment, useEffect, useState } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export function LarkSettings() {
  const toast = useToast();

  const [user, setUser] = useState(null);

  const [appId, setAppId] = useLocalStorage('app_id', '');
  const [appSecret, setAppSecret] = useLocalStorage('app_secret', '');

  const [isLoading, setIsLoading] = useState(false);

  const handleAppIdChange = (event) => setAppId(event.target.value);
  const handleAppSecretChange = (event) => setAppSecret(event.target.value);

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    ajax({ url: '/user/info', method: 'GET' }).then((info) => {
      console.log('info is', info);
      if (info?.lark?.app_id) {
        setAppId(info.lark.app_id);
      }
      if (info?.lark?.app_secret) {
        setAppSecret(info.lark.app_secret);
      }
      setUser(info?.data?.user);
    });
  }

  function isLarkBind() {
    return user?.lark?.app_id && user?.lark?.app_secret && user?.bitables?.[0];
  }

  async function bindLark() {
    if (isLoading) {
      return;
    }

    if (!appId || !appSecret) {
      alert('请填写 App Id 和 App Secret');
    }

    setIsLoading(true);

    try {
      await ajax({
        url: '/lark/bind-app-id',
        method: 'POST',
        body: {
          app_id: appId,
          app_secret: appSecret,
        },
      });

      await ajax({
        url: '/bitable/create',
        method: 'POST',
        body: {
          name: 'ClipTap',
        },
      });
    } catch (e) {
      toast({ title: e.msg || 'Unknown error', status: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  function rebindLark() {
    if (user?.lark?.app_id) {
      user.lark.app_id = '';
    }
    if (user?.lark?.app_secret) {
      user.lark.app_secret = '';
    }
    if (user) {
      setUser({ ...user });
    }
  }

  // 没有绑定飞书时显示的
  const bindLarkForm = (
    <Fragment>
      <Alert mb={3} status="info">
        <AlertIcon />
        免费绑定您的飞书账号，即可导出内容到飞书云文档。多端同步，永久保存。
      </Alert>

      <FormLabel>APP ID</FormLabel>
      <Input
        placeholder="请填入飞书应用的 APP ID"
        value={appId}
        onChange={handleAppIdChange}
      />
      <FormLabel>APP SECRET</FormLabel>
      <Input
        placeholder="请填入飞书应用的 APP SECRET"
        type="password"
        value={appSecret}
        onChange={handleAppSecretChange}
      />
      <Link color="blue.600">点此了解如何免费获取 App Id 与 App Secret</Link>
      <Box display="flex" justifyContent="space-around" m={6}>
        <Button onClick={bindLark}>
          {isLoading ? <Spinner mr={2}></Spinner> : null}绑定飞书
        </Button>
      </Box>
    </Fragment>
  );

  // 已经绑定了飞书时显示的
  const bitable = user?.bitables?.[0];

  const larkInfoForm = (
    <>
      <Alert mb={3} status="success">
        <AlertIcon /> 飞书账号已绑定
      </Alert>
      <Card mt={4} variant="outline">
        <CardBody pt={2} pb={2}>
          <VStack>
            <Box w="100%" display="flex" justifyContent="space-between">
              <Text>已关联飞书表格</Text>
              <Text color="gray.500">
                <Link
                  href={bitable?.app?.url}
                  display="flex"
                  alignItems="center"
                >
                  {bitable?.app?.name}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Text>
            </Box>
          </VStack>
        </CardBody>
      </Card>
      <Box display="flex" justifyContent="space-around" m={6}>
        <Button onClick={rebindLark}>重新绑定</Button>
      </Box>
    </>
  );

  return (
    <FormControl p={4}>
      {isLarkBind() ? larkInfoForm : bindLarkForm}
    </FormControl>
  );
}
