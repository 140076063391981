import { ChakraProvider } from '@chakra-ui/react';
import MainPage from './mainPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Settings } from './settings';
import { LarkSettings } from './larkSettings';
import { LandingPage } from './landingPage';
import { Vocab } from './vocab';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage />,
    },
    {
      path: '/landing',
      element: <LandingPage />,
    },
    {
      path: '/settings',
      element: <Settings />,
    },
    {
      path: '/settings/lark',
      element: <LarkSettings />,
    },
    {
      path: '/vocab',
      element: <Vocab />,
    },
  ]);

  return (
    <ChakraProvider>
      <RouterProvider router={router}></RouterProvider>
    </ChakraProvider>
  );
}

export default App;
