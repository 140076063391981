import './MainPage.css';
import BigBang from './bigbang';
import { useEffect, useState, useRef } from 'react';
import { searchBing } from './dictionary/bing';
import { CN_DOC_LINK } from './const';
import {
  Box,
  Button,
  ChakraProvider,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  Toast,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react';
import { AddIcon, SettingsIcon } from '@chakra-ui/icons';
import { Settings } from './settings';
import { useNavigate } from 'react-router-dom';
import { OutputTargetSelector } from './outputTargetSelector';

function MainPage() {
  const toast = useToast();
  const [text, setText] = useState('trying');
  const [items, setItems] = useState([]);
  const [defItems, setDefItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLoadingButtonIndex, setCurrentLoadingButtonIndex] =
    useState(null);
  const navigate = useNavigate();

  // 用于从导出选项组件获取当前的输出方法
  const outputTargetSelectorRef = useRef();

  useEffect(() => {
    const url = new URL(window.location.href);
    const text = url.searchParams.get('text');
    if (text && text.trim()) {
      setText(text);
    } else {
      navigate('/landing');
    }
  }, []);

  const onSelectionChange = async (selectedItems) => {
    setItems(selectedItems);
    const query = selectedItems
      .filter((i) => i.selected)
      .map((i) => i.text)
      .join(' ');
    if (query.trim()) {
      setIsLoading(true);
      try {
        const defs = await searchBing(query);
        setIsLoading(false);
        setDefItems(defs);
      } catch (e) {
        toast(e);
        setIsLoading(false);
      }
    }
  };

  const onAdd = async (item, index) => {
    if (currentLoadingButtonIndex === index) {
      return;
    }

    const ouputFunction =
      outputTargetSelectorRef?.current?.generateOuputFuntion();
    if (!ouputFunction) {
      toast({
        title: '保存位置还未配置',
      });
      return;
    }

    const outputConfig = {
      type: 'vocabulary',
      sentence: items
        .map((i) => {
          if (i.selected) {
            return `*${i.text}*`;
          } else {
            return i.text;
          }
        })
        .join(' '),
      word: item.headWord,
      reading: '',
      definition: `${item.pos} ${item.def}`,
      translation: '',
      extra: '',
      toast,
    };
    setCurrentLoadingButtonIndex(index);
    await ouputFunction(outputConfig);
    setCurrentLoadingButtonIndex(null);
    // const profile = "用户 1";
    // const deck = "test";
    // const type = "Basic";
    // const front = items.map(i => {
    //   if (i.selected) {
    //     return `<b>${i.text}</b>`;
    //   } else {
    //     return i.text;
    //   }
    // }).join(' ');
    // const back = `<div><b>${item.headWord}</b></div><span> ${item.pos}</span></div><div>${item.def}</div>`
    // const urlScheme = `anki://x-callback-url/addnote?profile=${profile}&type=${type}&deck=${deck}&fldFront=${front}&fldBack=${back}`;
    // window.open(urlScheme);
  };

  const defList = defItems.map((item, i) => (
    <Card marginLeft={2} marginRight={2}
      boxShadow={"none"}>
      <CardBody
        padding={2}
        border="none"
        boxShadow={"none"}
        borderBottom={"1px solid #e5e5ea"}>
        <HStack justify="space-between" padding={1}>
          <Text>
            <span className="def-headword">{item.headWord}</span>
            <span className="def-pos">{item.pos}</span>
            <span>{item.def}</span>
          </Text>
          <Box>
            <IconButton
              bgColor={"#f2f2f7"}
              onClick={() => onAdd(item, i)}
              icon={
                i === currentLoadingButtonIndex ? (
                  <Spinner></Spinner>
                ) : (
                  <AddIcon />
                )
              }
            />
          </Box>
        </HStack>
      </CardBody>
    </Card >
  ));

  return (
    <Box>
      <div className="App">
        <Box boxShadow="base" className="banner">
          <b>ClipTap</b>
          <Box alignItems="center" display="flex">
            {/* <SettingsIcon mr={2} onClick={() => {
              navigate("/settings");
            }} /> */}
            <Link
              color="#3785f7"
              textDecoration="none"
              _hover={{
                textDecoration: 'none',
                backgroundColor: 'none',
                color: '#3785f780',
              }}
              _visited={{
                textDecoration: 'none',
                backgroundColor: 'none',
                color: '#3785f780',
              }}
              marginRight={'16px'}
              href={'/settings'}
              isExternal
            >
              设置
            </Link>
            <Link
              color="#3785f7"
              textDecoration="none"
              _hover={{
                textDecoration: 'none',
                backgroundColor: 'none',
                color: '#3785f780',
              }}
              _visited={{
                textDecoration: 'none',
                backgroundColor: 'none',
                color: '#3785f780',
              }}
              href={CN_DOC_LINK}
              isExternal
            >
              帮助文档
            </Link>
          </Box>
        </Box>

        <div style={{ height: '40px' }}></div>

        <OutputTargetSelector ref={outputTargetSelectorRef} />

        <Box m={2} mt={2} mb={3}>
          <BigBang text={text} onSelectionChange={onSelectionChange}></BigBang>
        </Box>
        {isLoading ? (
          <Box
            p={1}
            display="flex"
            justifyItems="center"
            justifyContent="space-around"
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          defList
        )}
      </div>
    </Box>
  );
}

export default MainPage;
