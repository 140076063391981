import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Divider,
  Textarea,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import logo from './logo192.png';

export function LandingPage() {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const [text, setText] = useState(
    'Innovation is the harmonious symphony of imagination and perseverance, where every note is a discovery and every melody is a path to the future.'
  );
  const navigate = useNavigate();

  return (
    <Container maxW="container.xl" p={4} centerContent>
      <Box
        padding={8}
        bg={bgColor}
        color={textColor}
        borderRadius="lg"
        boxShadow="xl"
        textAlign="center"
        m={4}
        w="full"
      >
        <VStack spacing={8}>
          <img src={logo} />
          <Heading as="h1" size="2xl">
            ClipTap：智慧学习，随时随地
          </Heading>
          <Text fontSize="xl">精准理解，快捷整理</Text>
          <Text fontSize="lg" mb={5}>
            ClipTap，您的全能学习伙伴，无缝融入iOS，让语境中的词汇学习和资料整理随时触手可及。
          </Text>
          <Divider />
          <Box textAlign="left">
            <Heading as="h3" size="lg" mb={8}>
              功能特色：
            </Heading>
            <List spacing={6}>
              <ListItem textAlign="justify">
                <ListIcon as={CheckCircleIcon} color="#65c366" height="20px" boxSize="5" />
                即时词汇解析：在任何文本中，快速获取单词和短语的定义，提升您的阅读理解力。
              </ListItem>
              <ListItem textAlign="justify">
                <ListIcon as={CheckCircleIcon} color="#65c366" height="20px" boxSize="5" />
                灵活闪卡制作：从复杂资料到精炼卡片，只需数步，轻松构建您的个人化复习系统。
              </ListItem>
              <ListItem textAlign="justify">
                <ListIcon as={CheckCircleIcon} color="#65c366" height="20px" boxSize="5" />
                智能内容导出：将关键信息一键同步至飞书文档、Anki等应用，保持学习的连贯性。
              </ListItem>
            </List>
          </Box>
          <Divider />
          <Box textAlign="left">
            <Heading as="h3" size="lg" mb={4}>
              iOS上的无缝体验：
            </Heading>
            <Text fontSize="md" mb={2} textAlign="justify">
              全场景快捷启动：不论是在阅读App、浏览器，还是任何其他App中，都可以直接激活ClipTap，无需切换界面，学习与整理就在一瞬间。
            </Text>
          </Box>
          <Text fontSize="md" mb={5} textAlign="justify">
            ClipTap，您的即时学习中心，随拷贝随转化，让每一段文字都赋予学习的力量。
          </Text>
          <Textarea
            height="150px"
            value={text}
            onChange={(event) => setText(event.target.value)}
          ></Textarea>
          <Button
            size="lg"
            color="white"
            backgroundColor={"#3785f7"}
            onClick={() => navigate('/?text=' + text)} // Replace with actual navigation or function
          >
            尝试ClipTap
          </Button>
          <Text fontSize="lg" textAlign="justify">
            让您的每次阅读无论在何处，都变得更加高效和富有成效。🚀📖
          </Text>
        </VStack>
      </Box>
    </Container>
  );
}
