import { Box, IconButton, Text, useEditable, useToast } from '@chakra-ui/react';
import vocabData from './vocabdata/kaoyan_processed.json';
import Flashcard from './flashCard';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useSpring } from '@react-spring/web';
import { AnimatedBox } from './animatedComponents';

const GROUP_SIZE = 20;

function selectRandomElement(array) {
  if (array.length === 0) {
    return undefined;
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export function Vocab() {
  const [currentGroup, setCurrentGroup] = useLocalStorage('current_group', 0);
  const [word, setWord] = useState(null);
  const [localCacheForCurrentGroup, setLocalCacheForCurrentGroup] =
    useLocalStorage('local_cache_for_kaoyan_' + currentGroup, {});
  const toast = useToast();

  const [cardSprings, cardApi] = useSpring(() => ({
    config: { tension: 100, friction: 12 },
  }));

  const wordListForCurrentGroup = vocabData.slice(
    currentGroup * GROUP_SIZE,
    (currentGroup + 1) * GROUP_SIZE
  );

  const maxGroup = Math.floor(vocabData.length / GROUP_SIZE);

  useEffect(() => {
    setRandomWordForCurrentGroup();
  }, [currentGroup]);

  function getRandomUnDoneWord(array) {
    const unDoneArray = array.filter(
      (w) => localCacheForCurrentGroup[w.wordRank]?.status !== 'DONE'
    );
    if (unDoneArray.length === 0) {
      return null;
    } else {
      return selectRandomElement(unDoneArray);
    }
  }

  function setRandomWordForCurrentGroup() {
    const word = getRandomUnDoneWord(wordListForCurrentGroup);
    if (!word) {
      toast({ title: '本组单词都会了，请选择其他组' });
    } else {
      setWord(() => {
        cardApi.start({
          from: {
            opacity: 0.6,
            transform: 'scale(0.9) translateY(-20px)',
          },
          to: {
            opacity: 1,
            transform: 'scale(1) translateY(0px)',
          },
        });
        return word;
      });
    }
  }

  function prevGroup() {
    if (currentGroup === 0) {
      return;
    }
    setCurrentGroup((currentGroup) => currentGroup - 1);
  }

  function nextGroup() {
    if (currentGroup === maxGroup) {
      return;
    }
    setCurrentGroup((currentGroup) => currentGroup + 1);
  }

  function changeWord() {
    setRandomWordForCurrentGroup();
  }

  function setWordStatus(word, status) {
    setLocalCacheForCurrentGroup((localCacheForCurrentGroup) => {
      localCacheForCurrentGroup[word.wordRank] =
        localCacheForCurrentGroup[word.wordRank] || {};
      localCacheForCurrentGroup[word.wordRank] = {
        ...localCacheForCurrentGroup[word.wordRank],
        status,
      };
      return {
        ...localCacheForCurrentGroup,
      };
    });
  }

  const localCacheForCurrentWord = localCacheForCurrentGroup[word?.wordRank];

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        p={4}
        pb={0}
      >
        <IconButton
          onClick={prevGroup}
          icon={<ChevronLeftIcon></ChevronLeftIcon>}
        />
        <Text pl={2} pr={2}>
          第{currentGroup + 1} / {maxGroup + 1}组
        </Text>
        <IconButton
          onClick={nextGroup}
          icon={<ChevronRightIcon></ChevronRightIcon>}
        />
      </Box>
      {word ? (
        <AnimatedBox style={{ ...cardSprings }}>
          <Flashcard
            word={word}
            localCache={localCacheForCurrentWord}
            onChangeWord={changeWord}
            onWordOk={() => {
              if (localCacheForCurrentWord?.status === 'DONE') {
                setWordStatus(word, 'UNDONE');
              } else {
                setWordStatus(word, 'DONE');
              }
            }}
          ></Flashcard>
        </AnimatedBox>
      ) : null}
      <Box ml={4} mr={4}>
        {wordListForCurrentGroup.map((r) => (
          <Text
            display="inline-block"
            key={r.wordRank}
            p={1}
            color={r?.wordRank === word?.wordRank ? 'blue.400' : 'grey.100'}
            textDecoration={
              localCacheForCurrentGroup[r.wordRank]?.status === 'DONE'
                ? 'line-through'
                : ''
            }
            onClick={() => {
              setWord(r);
            }}
          >
            {r.headWord}
          </Text>
        ))}
      </Box>
    </Box>
  );
}
