const headers = () => ({
  headers: {
    register_id: JSON.parse(localStorage.getItem('register_id')),
    app_id: JSON.parse(localStorage.getItem('app_id')),
    app_secret: JSON.parse(localStorage.getItem('app_secret')),
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export async function ajax({ url, method, body }) {
  const resp = await fetch(url, {
    method,
    ...headers(),
    ...(body ? { body: JSON.stringify(body) } : {}),
  }).then((r) => r.text());
  let json = JSON.parse(resp);
  try {
    // 正常返回
  } catch {
    throw {
      code: 500,
      msg: 'Json parse error',
    };
  }
  if (json.code != 0) {
    throw json;
  }
  return json;
}
