import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  Switch,
  IconButton,
} from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { MdOutlineVolumeUp, MdSettings } from 'react-icons/md';
import { useSpring } from '@react-spring/web';
import { AnimatedBox } from './animatedComponents';

function playYoudaoPronunciation(word) {
  // Check if a word is provided
  if (!word) {
    console.error('No word provided for pronunciation.');
    return;
  }

  // Create the audio URL with the provided word and type parameter for pronunciation
  var audioUrl =
    'https://dict.youdao.com/dictvoice?audio=' +
    encodeURIComponent(word) +
    '&type=2';

  // Create a new audio element
  var audioElement = new Audio(audioUrl);

  // Play the audio
  audioElement.play().catch((e) => {
    console.error('Error playing the audio:', e);
  });
}

const Flashcard = ({ word, localCache, onWordOk, onChangeWord }) => {
  const [showDefinition, setShowDefinition] = useState(false);
  const [autoPlay, setAutoPlay] = useLocalStorage('autoplay', false);
  const [defSprings, defApi] = useSpring(() => ({
    config: { tension: 300, friction: 20 },
  }));

  const toggleDefinition = () => {
    if (!showDefinition) {
      defApi.start({
        from: {
          opacity: 0,
          transform: 'scale(0.5) translateY(-30px)',
        },
        to: {
          opacity: 1,
          transform: 'scale(1) translateY(0px)',
        },
      });
    }

    if (!showDefinition && autoPlay) {
      playPronunciation();
    }
    setShowDefinition(!showDefinition);
  };

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay);
  };

  const playPronunciation = (e) => {
    e?.stopPropagation();
    playYoudaoPronunciation(word.headWord);
  };

  const youEnlish = () => {
    window.open(`https://youglish.com/pronounce/${word.headWord}/english?`);
  };

  useEffect(() => {
    if (autoPlay) {
      playPronunciation();
    }
  }, [autoPlay, word.headWord]);

  return (
    <Box p={4} borderWidth={1} borderRadius="md" m={4}>
      <VStack spacing={4} align="stretch">
        <VStack
          spacing={4}
          align="stretch"
          height={'150px'}
          onClick={toggleDefinition}
        >
          <Text fontSize="2xl" fontWeight="bold" align={'center'}>
            {word.headWord}
            <IconButton
              ml={2}
              onClick={playPronunciation}
              icon={<MdOutlineVolumeUp></MdOutlineVolumeUp>}
            />
          </Text>
          {showDefinition && (
            <AnimatedBox style={{ ...defSprings }}>
              {word.trans.map((definition, index) => (
                <Text key={index} fontSize="lg" align="center">
                  {definition}
                </Text>
              ))}
            </AnimatedBox>
          )}
        </VStack>
        <Button colorScheme="blue" onClick={toggleDefinition}>
          {showDefinition ? '隐藏释义' : '查看释义'}
        </Button>
        <Button
          colorScheme="teal"
          onClick={() => {
            setShowDefinition(false);
            onChangeWord();
          }}
        >
          换一个
        </Button>
        <Button colorScheme="green" onClick={onWordOk}>
          {localCache?.status === 'DONE' ? '我又不会了' : '我会了'}
        </Button>
        {/* <Button colorScheme="green" onClick={youEnlish}>
          Youtube例句
        </Button> */}
        <Box display="flex" alignItems="center">
          <Text mr={2}>Auto Play:</Text>
          <Switch isChecked={autoPlay} onChange={toggleAutoPlay} />
        </Box>
      </VStack>
    </Box>
  );
};

export default Flashcard;
