import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { ajax } from './ajax';
import { formatDate, maskPhone } from './utils';
import { CN_PURCHASE_LINK } from './const';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export function Settings() {
  const navigate = useNavigate();

  const [registerId, setRegisterId] = useLocalStorage('register_id', '');
  const [appId, setAppId] = useLocalStorage('app_id', '');
  const [appSecret, setAppSecret] = useLocalStorage('app_secret', '');

  const handleRegisterIdChange = (event) => setRegisterId(event.target.value);
  const handleAppIdChange = (event) => setAppId(event.target.value);
  const handleAppSecretChange = (event) => setAppSecret(event.target.value);

  const [user, setUser] = useState();

  function refreshUser() {
    ajax({ url: '/user/info', method: 'GET' }).then((info) => {
      setUser(info?.data?.user);
    });
  }

  async function changeRegisterId() {
    if (window.confirm('本地原有注册信息将会被清除，确定要更换吗？')) {
      setRegisterId('');
    }
  }

  function buyRegisterId() {
    window.open(CN_PURCHASE_LINK);
  }

  function hasBindLark(user) {
    return (
      user?.lark?.app_id && user?.lark?.app_secret && user?.bitables?.length > 0
    );
  }

  function larkStatus(user) {
    return hasBindLark(user) ? '已绑定' : '去绑定';
  }

  function goLarkSettings() {
    navigate('/settings/lark');
  }

  useEffect(() => {
    refreshUser();
  }, [registerId]);

  const registerForm = (
    <Box>
      <Alert status="info">
        <AlertIcon />
        未注册，请在下方填入注册码，解锁高级功能。
      </Alert>
      <Box display="flex" justifyContent="space-around" m={3}>
        <Button onClick={buyRegisterId}>购买注册码</Button>
      </Box>
      <FormLabel>注册码</FormLabel>
      <Input
        placeholder="请填入您购买的 ClipTap 注册码"
        type="password"
        value={registerId}
        onChange={handleRegisterIdChange}
      />
    </Box>
  );

  const userInfo = (
    <Box>
      <Alert status="success">
        <AlertIcon />
        高级功能已解锁
      </Alert>
      <Card mt={4} variant="outline">
        <CardBody pt={2} pb={2}>
          <VStack>
            <Box w="100%" display="flex" justifyContent="space-between">
              <Text>用户名</Text>
              <Text color="gray.500">{user?.name}</Text>
            </Box>
            <Divider />
            <Box w="100%" display="flex" justifyContent="space-between">
              <Text>手机</Text>
              <Text color="gray.500">{maskPhone(user?.phone)}</Text>
            </Box>
            <Divider />
            <Box w="100%" display="flex" justifyContent="space-between">
              <Text>有效期至</Text>
              <Text color="gray.500"> {formatDate(user?.expires_at)}</Text>
            </Box>
            <Divider />
            <Box
              onClick={goLarkSettings}
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>飞书文档</Text>
              <Text color="gray.500" display="flex" alignItems="center">
                {' '}
                {larkStatus(user)}{' '}
                <ChevronRightIcon color="gray.300" boxSize="1.5em" />
              </Text>
            </Box>
          </VStack>
        </CardBody>
      </Card>
      <Box display="flex" justifyContent="space-around" m={6}>
        <Button onClick={changeRegisterId}>更换注册码</Button>
      </Box>
    </Box>
  );

  return <FormControl p={4}>{user ? userInfo : registerForm}</FormControl>;
}
