export function maskPhone(phone) {
  const maskNum = 4;

  if (!phone) {
    return '';
  }

  if (phone.length <= maskNum) {
    return '****';
  }
  return (
    new Array(phone.length - maskNum).fill('*').join('') +
    phone.slice(phone.length - maskNum, phone.length)
  );
}

export function formatDate(dateStr) {
  if (!dateStr) {
    return '';
  }

  const date = new Date(Date.parse(dateStr));
  return date.toLocaleDateString();
}
